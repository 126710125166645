import { isNaN, isNil } from 'lodash-es';
import { RobotUtils } from '../../../../utils/robot/RobotUtils';
import {
  BATTERY_DATA_DEFAULT,
  REPEAT_PROGRESS_DEFAULT,
  ROBOT_WATER_TANK_CAPACITY,
  SCHEDULED_TIME_DEFAULT,
} from './utils/constants';
import {
  RobotStatus,
  MachineConnectionStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  BatteryData,
  RepeatProgress,
  ScheduledTime,
  Telemetry,
} from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { Optional } from 'lib/types/Optional';

export class RobotViewModel {
  private robot: Machine;

  constructor(robot: Machine) {
    this.robot = robot;
  }

  public get getCleaningRouteLabel(): string {
    const generalTranslateKey = 'robotDashboard.cleaningRoute.label.';

    if (
      !this.robot.connectionStatus ||
      (!this.robot.connectionStatus && !this.robot.robotStatus) ||
      (!this.robot.connectionStatus && this.robot.robotStatus) ||
      this.robot.connectionStatus !== MachineConnectionStatus.Online
    ) {
      return generalTranslateKey.concat('lastCleaningRoute');
    }

    switch (this.robot.robotStatus) {
      case RobotStatus.Autonomous:
        return generalTranslateKey.concat('cleaningRoute');

      case RobotStatus.Charging:
      case RobotStatus.Docking:
      case RobotStatus.Idle:
      case RobotStatus.ManualCleaning:
      case RobotStatus.Exploration:
        return generalTranslateKey.concat('nextCleaningRoute');

      default:
        return generalTranslateKey.concat('lastCleaningRoute');
    }
  }

  public get getRepeatPathProgressData(): RepeatProgress {
    const repeatPathProgress = RobotUtils.getStateData(Telemetry.RepeatProgress, this.robot);

    if (isNil(repeatPathProgress)) {
      return REPEAT_PROGRESS_DEFAULT;
    }

    if (!isNil(repeatPathProgress) && isNil(repeatPathProgress.stateValue)) {
      return REPEAT_PROGRESS_DEFAULT;
    }

    if (!isNil(repeatPathProgress) && !isNil(repeatPathProgress.stateValue)) {
      const { progress, routeName, repeatPathId } = JSON.parse(repeatPathProgress.stateValue);

      if (!isNil(progress) && !isNaN(progress)) {
        const progressConvertedData = parseFloat(progress);
        const progressRoundData = Math.round(progressConvertedData * 100);

        return {
          progress: Math.min(Math.max(progressRoundData, 0), 100),
          repeatPathId,
          routeName,
        };
      }

      return REPEAT_PROGRESS_DEFAULT;
    }

    return REPEAT_PROGRESS_DEFAULT;
  }

  public get getNextTask(): Optional<ScheduledTime> {
    const now = new Date().toISOString();

    try {
      const scheduledTasks = this.getScheduledTime;

      if (!scheduledTasks || scheduledTasks.length < 1) {
        return null;
      }

      const scheduledTasksGreaterThanNow = scheduledTasks?.filter(scheduledTask => scheduledTask.startDate > now);

      const sortScheduledTasksGreaterThanNow = scheduledTasksGreaterThanNow.sort(
        (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      if (sortScheduledTasksGreaterThanNow.length > 0) {
        return sortScheduledTasksGreaterThanNow[0];
      }
    } catch (ex) {
      return null;
    }

    return null;
  }

  public get getBatteryChargeLevel(): number {
    const batteryChargeLevel = RobotUtils.getStateData(Telemetry.BatteryChargeLevel, this.robot);

    if (!isNil(batteryChargeLevel) && !isNil(batteryChargeLevel.stateValue) && !isNaN(batteryChargeLevel.stateValue)) {
      const batteryData = parseFloat(batteryChargeLevel.stateValue);

      return Math.min(Math.max(Math.round(batteryData * 100), 0), 100);
    }

    return 0;
  }

  public get getWaterPercentage(): number {
    const waterLevel = this.getFreshWaterLevel;
    if (!this.robot.type?.name || typeof waterLevel !== 'number') return 0;

    const machineType = this.robot.type?.name?.toLowerCase();
    const liters = machineType ? ROBOT_WATER_TANK_CAPACITY[machineType] : 0;
    const percents = Math.min(Math.max(Math.round((waterLevel / liters) * 100), 0), 100);
    return percents;
  }

  public get getFreshWaterLevel(): number | string {
    const freshWaterLevel = RobotUtils.getStateData(Telemetry.FreshWaterLevel, this.robot);

    if (isNil(freshWaterLevel)) {
      return '-';
    }

    if (!isNil(freshWaterLevel) && isNil(freshWaterLevel.stateValue)) {
      return '-';
    }

    if (!isNil(freshWaterLevel) && !isNil(freshWaterLevel.stateValue) && !isNaN(freshWaterLevel.stateValue)) {
      const freshWaterLevelData = parseFloat(freshWaterLevel.stateValue);
      return Math.min(Math.max(Math.round(freshWaterLevelData), 0));
    }

    return 0;
  }

  public get getRobotCleaningRouteNameAndDate(): { routeName: string; date: string } {
    const robotLatestCleaningTaskReport = this.robot.cleaningTaskReportLatestGet?.data;
    const scheduledNextTask = this.getNextTask;
    const { routeName } = this.getRepeatPathProgressData;

    if (this.robot.connectionStatus === MachineConnectionStatus.Online) {
      if (!this.robot.robotStatus) {
        return {
          routeName: robotLatestCleaningTaskReport?.routeName || '',
          date: robotLatestCleaningTaskReport?.finishedAt || '',
        };
      }

      if (this.robot.robotStatus === RobotStatus.Autonomous) {
        return { routeName, date: '' };
      }

      return {
        routeName: scheduledNextTask?.taskName || '',
        date: scheduledNextTask?.startDate || '',
      };
    }

    return {
      routeName: robotLatestCleaningTaskReport?.routeName || '',
      date: robotLatestCleaningTaskReport?.finishedAt || '',
    };
  }

  public get getScheduledTime(): ScheduledTime[] {
    const scheduledTasks = RobotUtils.getStateData(Telemetry.ScheduledTasks, this.robot);

    if (scheduledTasks) {
      const scheduledTime = JSON.parse(scheduledTasks.stateValue);

      if (!Array.isArray(scheduledTime) && scheduledTime.length) {
        return SCHEDULED_TIME_DEFAULT;
      }

      return scheduledTime;
    }

    return SCHEDULED_TIME_DEFAULT;
  }

  public get getBatteryDataCV50(): BatteryData {
    const batteryData = RobotUtils.getStateData(Telemetry.BatteryData, this.robot);

    if (batteryData) {
      const batteryCV50 = JSON.parse(batteryData.stateValue);

      if (isNil(batteryCV50)) {
        return BATTERY_DATA_DEFAULT;
      }

      if (
        isNil(batteryCV50.batteryPack1) ||
        isNaN(batteryCV50.batteryPack1.stateOfCharge) ||
        isNil(batteryCV50.batteryPack1.stateOfCharge)
      ) {
        return {
          batteryPack1: BATTERY_DATA_DEFAULT.batteryPack1,
          ...batteryCV50,
        };
      }

      if (
        isNil(batteryCV50.batteryPack2) ||
        isNaN(batteryCV50.batteryPack2.stateOfCharge) ||
        isNil(batteryCV50.batteryPack2.stateOfCharge)
      ) {
        return {
          ...batteryCV50,
          batteryPack2: BATTERY_DATA_DEFAULT.batteryPack2,
        };
      }

      return {
        batteryPack1: {
          stateOfCharge: Math.min(Math.max(Math.round(batteryCV50.batteryPack1.stateOfCharge), 0), 100),
        },
        batteryPack2: {
          stateOfCharge: Math.min(Math.max(Math.round(batteryCV50.batteryPack2.stateOfCharge), 0), 100),
        },
      };
    }

    return BATTERY_DATA_DEFAULT;
  }

  public get getCharging(): boolean {
    const robotCharging = RobotUtils.getStateData(Telemetry.Charging, this.robot);
    const robotStatus = this.robot.robotStatus;

    let isCharging = false;
    let isRobotStatusCharging = false;

    if (!isNil(robotCharging) && !isNil(robotCharging.stateValue)) {
      isCharging = robotCharging.stateValue === 'true';
    }

    if (!isNil(robotStatus)) {
      isRobotStatusCharging = robotStatus === RobotStatus.Charging;
    }

    return isCharging || isRobotStatusCharging;
  }
}
