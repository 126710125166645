import { ApolloQueryResult, gql } from '@apollo/client';
import { AxiosResponse } from 'axios/index';
import { inject, injectable } from 'tsyringe';
import {
  InputFilterCleaningTaskReportsList,
  InputPagingOptions,
  InputSortOptions,
  QueryCleaningTaskReportRobotRouteImageArgs,
} from '../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  CleaningTaskReportListResponse,
  CleaningTaskReportRouteImageResponse,
} from './interfaces/CleaningTaskReport.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import type { RestClient } from 'app/cross-cutting-concerns/communication/clients/RestClientFactory';

export interface CleaningTaskReportClientListOptions {
  filter?: InputFilterCleaningTaskReportsList;
  sortOptions?: InputSortOptions;
  paginationOptions: InputPagingOptions;
}

export type CleaningTaskReportClientGetRobotRouteImageUrlOptions = QueryCleaningTaskReportRobotRouteImageArgs;

export interface CleaningTaskReportClientGetRobotRouteImageDataOptions {
  robotRouteImageUrl: string;
}

@injectable()
export class CleaningTaskReportClient {
  constructor(
    @inject('GraphQlClient') private graphQlClient: GraphQlClient,
    @inject('RestClient') private restClient: RestClient
  ) {}

  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: CleaningTaskReportClientListOptions): Promise<ApolloQueryResult<CleaningTaskReportListResponse>> =>
    this.graphQlClient.query({
      query: gql`
        query CleaningTaskReports(
          $filter: InputFilterCleaningTaskReportsList!
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions!
        ) {
          cleaningTaskReports(filter: $filter, sortOptions: $sortOptions, paginationOptions: $paginationOptions) {
            metadata {
              totalCount
              paginationToken
            }
            data {
              id
              serialNumber
              materialNumber
              actualOperatingTimeInSeconds
              startedAt
              finishedAt
              routeName
              pathCoverage
              cleanedSquareMeter
              taskCompletion
              repeatPathId
              machine {
                id
                name
                type {
                  name
                }
                site {
                  id
                  name
                }
                metadata {
                  inventoryNumber
                }
              }
              currentRepeat
              executionId
              totalRepeats
              routineName
              distance
              battery
              water
              detergents
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  public getRobotRouteImageUrl = async ({
    machineId,
    finishedAt,
  }: CleaningTaskReportClientGetRobotRouteImageUrlOptions): Promise<
    ApolloQueryResult<CleaningTaskReportRouteImageResponse>
  > =>
    this.graphQlClient.query({
      query: gql`
        query CleaningTaskReportRobotRouteImage($machineId: String!, $finishedAt: String!) {
          cleaningTaskReportRobotRouteImage(machineId: $machineId, finishedAt: $finishedAt) {
            data
          }
        }
      `,
      variables: {
        machineId,
        finishedAt,
      },
    });

  public getRobotRouteImageData = async ({
    robotRouteImageUrl,
  }: CleaningTaskReportClientGetRobotRouteImageDataOptions): Promise<AxiosResponse<Blob>> =>
    this.restClient(robotRouteImageUrl, { responseType: 'blob' });
}
