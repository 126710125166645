import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import {
  RobotDashboardKpIsData,
  RobotDashboardTotalCleanedAreaData,
  RobotDashboardTotalCleanedHrsData,
  RobotTaskCompletionStatisticData,
  Site,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { SiteData } from 'app/modules/site-management/interfaces/Site.types';
import { SubscriptionMachineEvent } from 'app/modules/machine-inventory/interfaces/MachineSubscription.types';

export const selectRobotsWithTotalCleanedAreaData = (state: IState): RobotDashboardTotalCleanedAreaData[] =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotsWithTotalCleanedArea.data;

export const selectIsRobotsWithTotalCleanedAreaLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotsWithTotalCleanedArea.isLoading;

export const selectRobotsWithTotalCleanedHourData = (state: IState): RobotDashboardTotalCleanedHrsData[] =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotsWithTotalCleanedHour.data;

export const selectIsRobotsWithTotalCleanedHourLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotsWithTotalCleanedHour.isLoading;

export const selectRobotDashboardKPIsData = (state: IState): Optional<RobotDashboardKpIsData> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardKPIs.data;

export const selectIsRobotDashboardKPIsLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardKPIs.isLoading;

export const selectRobotDashboardKPIsDataB50 = (state: IState): Optional<RobotDashboardKpIsData> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardKPIs.dataB50;

export const selectIsRobotDashboardKPIsLoadingB50 = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardKPIs.isB50Loading;

export const selectRobotDashboardKPIsDataCV50 = (state: IState): Optional<RobotDashboardKpIsData> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardKPIs.dataCV50;

export const selectIsRobotDashboardKPIsLoadingCV50 = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardKPIs.isCV50Loading;

export const selectRobotsTasksCompletionData = (state: IState): Optional<RobotTaskCompletionStatisticData> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotsTasksCompletion.data;

export const selectIsRobotsTasksCompletionLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotsTasksCompletion.isLoading;

export const selectAvailableSites = (state: IState): Optional<Site[]> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.available.sites;

export const selectActiveSites = (state: IState): string[] =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.active.sites;

export const selectStatus = (state: IState): string[] =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.active.status;

export const selectGroupBy = (state: IState): Optional<string> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.active.groupBy;

export const selectSitesIsLoading = (state: IState): boolean =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.isLoading;

export const selectPeriodStartDate = (state: IState): string =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.active.period.startDate;

export const selectPeriodEndDate = (state: IState): string =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.active.period.endDate;

export const selectIsHidingOfflineRobots = (state: IState): boolean =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.active.isHidingOfflineRobots;

export const selectRobotIdsFilter = (state: IState): string[] =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotDashboardFilter.active.robotIds;

export const selectRobotListData = (state: IState): Optional<Machine[]> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotList.data;

export const selectRobotListDataB50 = (state: IState): Optional<Machine[]> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotList.dataB50;

export const selectRobotListDataCV50 = (state: IState): Optional<Machine[]> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotList.dataCV50;

export const selectIsRobotListLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotList.isLoading;

export const selectAreRobotListPicturesLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotList.arePicturesLoading;

export const selectAreRobotListTelemetriesLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotList.areTelemetriesLoading;

export const selectAreRobotListLatestCtrLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotList.areLatestCtrLoading;

export const selectAreRobotListLatestRoutineLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotList.areLatestRoutineLoading;

export const selectRobotUnassignedListData = (state: IState): Optional<Machine[]> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotUnassignedList.data;

export const selectIsRobotUnassignedListLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotUnassignedList.isLoading;

export const selectAreRobotUnassignedListPicturesLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotUnassignedList.arePicturesLoading;

export const selectAreRobotUnassignedListTelemetriesLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotUnassignedList.areTelemetriesLoading;

export const selectAreRobotUnassignedListLatestCtrLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotUnassignedList.areLatestCtrLoading;

export const selectAreRobotUnassignedListLatestRoutineLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotUnassignedList.areLatestRoutineLoading;

export const selectRobotListGroupedBySiteSiteList = (state: IState): SiteData[] =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.data;

export const selectIsRobotListGroupedBySiteSiteListLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.isLoading;

export const selectIsRobotListGroupedBySiteWithCleaningStatisticLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.isCleaningStatisticLoading;

export const selectIsRobotListGroupedBySiteWithRobotsLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.areMachinesLoading;

export const selectIsRobotListGroupedBySiteWithRobotsPicturesLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.areMachinesPicturesLoading;

export const selectIsRobotListGroupedBySiteWithRobotsTelemetriesLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.areTelemetriesLoading;

export const selectIsRobotListGroupedBySiteWithRobotsLatestCtrLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.areLatestCtrLoading;

export const selectIsRobotListGroupedBySiteWithRobotsLatestRoutineLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.areLatestRoutineLoading;

export const selectRobotListGroupedBySiteRobotIds = (state: IState): string[] =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotListGroupedBySite.robotIds;

export const selectRobotDashboardUnassignedRobotsKPIsData = (state: IState): Optional<RobotDashboardKpIsData> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.unassignedRobotKPIs.data;

export const selectIsRobotDashboardUnassignedRobotsKPIsLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.unassignedRobotKPIs.isLoading;

export const selectRobotDashboardUnassignedRobotsKPIsDataB50 = (state: IState): Optional<RobotDashboardKpIsData> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.unassignedRobotKPIs.dataB50;

export const selectIsRobotDashboardUnassignedRobotsKPIsLoadingB50 = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.unassignedRobotKPIs.isB50Loading;

export const selectRobotDashboardUnassignedRobotsKPIsDataCV50 = (state: IState): Optional<RobotDashboardKpIsData> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.unassignedRobotKPIs.dataCV50;

export const selectIsRobotDashboardUnassignedRobotsKPIsLoadingCV50 = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.unassignedRobotKPIs.isCV50Loading;

export const selectRobotDashboardIsRedirected = (state: IState): boolean =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.isRedirected;

export const selectRobotDashboardRealTimeUpdatedEvent = (state: IState): Optional<SubscriptionMachineEvent> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotRealTimeUpdatedEvent;

export const selectRobotDashboardRealTimeRobotId = (state: IState): Optional<string> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.robotRealTimeRobotId;

export const selectRobotDashboardRealTimeIsComponentFadedOut = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].dashboard.isComponentFadedOut;
