import { EventChannel } from 'redux-saga';
import {
  CallEffect,
  ForkEffect,
  GetContextEffect,
  JoinEffect,
  PutEffect,
  SelectEffect,
  TakeEffect,
} from 'redux-saga/effects';
import {
  call,
  cancel,
  delay,
  fork,
  getContext,
  join,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  throttle,
} from 'typed-redux-saga';
import { isNil } from 'lodash-es';
import { GraphQlSubscriptionMessage } from '../../../../../cross-cutting-concerns/communication/interfaces/graphql.types';
import { SagaUtils } from '../../../../../cross-cutting-concerns/state-management/utils/SagaUtils';
import { RobotUtils } from '../../../../../utils/robot/RobotUtils';
import {
  RobotDashboardGetTasksCompletionErrorAction,
  RobotDashboardGetTasksCompletionRequestAction,
  RobotDashboardGetTasksCompletionSuccessAction,
  RobotDashboardKPIsErrorAction,
  RobotDashboardKPIsForB50ErrorAction,
  RobotDashboardKPIsForB50RequestAction,
  RobotDashboardKPIsForB50SuccessAction,
  RobotDashboardKPIsForCV50ErrorAction,
  RobotDashboardKPIsForCV50RequestAction,
  RobotDashboardKPIsForCV50SuccessAction,
  RobotDashboardKPIsRequestAction,
  RobotDashboardKPIsSuccessAction,
  RobotDashboardListRobotsWithTotalCleanedAreaErrorAction,
  RobotDashboardListRobotsWithTotalCleanedAreaRequestAction,
  RobotDashboardListRobotsWithTotalCleanedAreaSuccessAction,
  RobotDashboardListRobotsWithTotalCleanedHourErrorAction,
  RobotDashboardListRobotsWithTotalCleanedHourRequestAction,
  RobotDashboardListRobotsWithTotalCleanedHourSuccessAction,
  RobotDashboardListSitesErrorAction,
  RobotDashboardListSitesRequestAction,
  RobotDashboardListSitesSuccessAction,
  RobotDashboardRealTimeDataChangedAction,
  RobotListErrorAction,
  RobotListGroupedBySiteListSitesErrorAction,
  RobotListGroupedBySiteListSitesRequestAction,
  RobotListGroupedBySiteListSitesSuccessAction,
  RobotListGroupedBySiteListSitesWithRobotPicturesSuccessAction,
  RobotListGroupedBySiteListSitesWithRobotSuccessAction,
  RobotListLatestCtrsSuccessAction,
  RobotListPicturesSuccessAction,
  RobotListRequestAction,
  RobotListSuccessAction,
  RobotListTelemetriesSuccessAction,
  RobotUnassignedListErrorAction,
  RobotUnassignedListLatestCtrsSuccessAction,
  RobotUnassignedListPicturesSuccessAction,
  RobotUnassignedListRequestAction,
  RobotUnassignedListSuccessAction,
  RobotUnassignedListTelemetriesSuccessAction,
} from './RobotDashboardActions.types';
import { RobotDashboardActions, SubscribeToMachineUpdateAction } from './RobotDashboardSlice';
import {
  selectGroupBy,
  selectPeriodEndDate,
  selectPeriodStartDate,
  selectRobotIdsFilter,
  selectRobotListGroupedBySiteRobotIds,
  selectRobotUnassignedListData,
} from './RobotDashboardSelectors';
import {
  SiteList,
  SiteMachinesList,
  SiteMachinesListWithVariantData,
} from 'app/modules/site-management/interfaces/Site.types';
import {
  RobotDashboardKPIsResponse,
  RobotDashboardTasksCompletionStatisticResponse,
  RobotDashboardTotalCleanedAreaResponse,
  RobotDashboardTotalCleanedHourResponse,
} from 'app/modules/machine-inventory/interfaces/Robot.types';
import {
  MachineList,
  MachineListLatestCtrData,
  MachineListTelemetryData,
  MachineListVariantData,
  SubscriptionMachineUpdateResult,
} from 'app/modules/machine-inventory/interfaces/Machine.types';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  MachineSiteChangedEvent,
  MachineUpdateConnectionStatus,
  MachineUpdateRobotStatus,
  MachineUpdateTelemetry,
  SubscriptionMachineEvent,
} from 'app/modules/machine-inventory/interfaces/MachineSubscription.types';
import { ROBOT_LIST_GROUP_BY } from 'config/constants';
import {
  MachineConnectionStatus,
  MachineUpdate,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* listRobotsWithTotalCleanedAreaSaga(
  action: RobotDashboardListRobotsWithTotalCleanedAreaRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardTotalCleanedAreaResponse>
  | PutEffect<RobotDashboardListRobotsWithTotalCleanedAreaSuccessAction>
  | PutEffect<RobotDashboardListRobotsWithTotalCleanedAreaErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.listRobotsWithCleanedArea, action.payload);
    yield* put(RobotDashboardActions.robotDashboardListRobotsWithTotalCleanedAreaSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotDashboardListRobotsWithTotalCleanedAreaError({
        error,
      })
    );
  }
}

export function* listRobotsWithTotalCleanedHourSaga(
  action: RobotDashboardListRobotsWithTotalCleanedHourRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardTotalCleanedHourResponse>
  | PutEffect<RobotDashboardListRobotsWithTotalCleanedHourSuccessAction>
  | PutEffect<RobotDashboardListRobotsWithTotalCleanedHourErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response: RobotDashboardTotalCleanedHourResponse = yield* call(
      robotService.listRobotsWithTotalCleanedHour,
      action.payload
    );
    yield* put(RobotDashboardActions.robotDashboardTotalCleanedHourSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotDashboardTotalCleanedHourError({
        error,
      })
    );
  }
}

export function* robotDashboardKPIsSaga(
  action: RobotDashboardKPIsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardKPIsResponse>
  | PutEffect<RobotDashboardKPIsSuccessAction>
  | PutEffect<RobotDashboardKPIsErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.robotsKPIs, action.payload);
    yield* put(RobotDashboardActions.robotDashboardKPIsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotDashboardKPIsError({
        error,
      })
    );
  }
}

export function* robotDashboardKPIsForB50Saga(
  action: RobotDashboardKPIsForB50RequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardKPIsResponse>
  | PutEffect<RobotDashboardKPIsForB50SuccessAction>
  | PutEffect<RobotDashboardKPIsForB50ErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.robotsKPIs, action.payload);
    yield* put(RobotDashboardActions.robotDashboardKPIsForB50Success(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotDashboardKPIsForB50Error({
        error,
      })
    );
  }
}

export function* robotDashboardKPIsForCV50Saga(
  action: RobotDashboardKPIsForCV50RequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardKPIsResponse>
  | PutEffect<RobotDashboardKPIsForCV50SuccessAction>
  | PutEffect<RobotDashboardKPIsForCV50ErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.robotsKPIs, action.payload);
    yield* put(RobotDashboardActions.robotDashboardKPIsForCV50Success(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotDashboardKPIsForCV50Error({
        error,
      })
    );
  }
}

export function* getRobotsTasksCompletionSaga(
  action: RobotDashboardGetTasksCompletionRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardTasksCompletionStatisticResponse>
  | PutEffect<RobotDashboardGetTasksCompletionSuccessAction>
  | PutEffect<RobotDashboardGetTasksCompletionErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.getRobotDashboardTasksCompletion, action.payload);
    yield* put(RobotDashboardActions.robotDashboardGetTasksCompletionSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotDashboardGetTasksCompletionError({
        error,
      })
    );
  }
}

export function* getSitesListSaga(
  action: RobotDashboardListSitesRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<SiteList>
  | PutEffect<RobotDashboardListSitesSuccessAction>
  | PutEffect<RobotDashboardListSitesErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.listForSelect, action.payload);

    yield* put(RobotDashboardActions.robotDashboardGetSitesListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(RobotDashboardActions.robotDashboardGetSitesListError({ error }));
  }
}

export function* listRobotsSaga(
  action: RobotListRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<MachineList>
  | ForkEffect<MachineListVariantData>
  | ForkEffect<MachineListTelemetryData>
  | ForkEffect<MachineListLatestCtrData>
  | JoinEffect
  | PutEffect<RobotListSuccessAction>
  | PutEffect<RobotListPicturesSuccessAction>
  | PutEffect<RobotListTelemetriesSuccessAction>
  | PutEffect<RobotListLatestCtrsSuccessAction>
  | PutEffect<RobotListErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machinesResponseTask = yield* fork(machineService.listRobots, action.payload);
    const machinePicturesResponseTask = yield* fork(machineService.listPictures, action.payload);
    const machineTelemetriesResponseTask = yield* fork(machineService.robotsWithTelemetries, action.payload);
    const machineLatestCtrsResponseTask = yield* fork(machineService.listWithLatestCtr, action.payload);
    const machineLatestRoutinesResponseTask = yield* fork(machineService.listWithLatestRoutine, action.payload);

    const machinesResponse = yield* join(machinesResponseTask);
    yield* put(RobotDashboardActions.robotListSuccess(machinesResponse));

    const machinePicturesResponse = yield* join(machinePicturesResponseTask);
    yield* put(RobotDashboardActions.robotListPicturesSuccess(machinePicturesResponse));

    const machineTelemetriesResponse = yield* join(machineTelemetriesResponseTask);
    yield* put(RobotDashboardActions.robotListTelemetriesSuccess(machineTelemetriesResponse));

    const machineLatestCtrsResponse = yield* join(machineLatestCtrsResponseTask);
    yield* put(RobotDashboardActions.robotListLatesCtrsSuccess(machineLatestCtrsResponse));

    const machineLatestRoutinesResponse = yield* join(machineLatestRoutinesResponseTask);
    yield* put(RobotDashboardActions.robotListLatesRoutinesSuccess(machineLatestRoutinesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotListError({
        error,
      })
    );
  }
}

export function* listUnassignedRobotsSaga(
  action: RobotUnassignedListRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<MachineList>
  | ForkEffect<MachineListVariantData>
  | ForkEffect<MachineListTelemetryData>
  | ForkEffect<MachineListLatestCtrData>
  | JoinEffect
  | PutEffect<RobotUnassignedListSuccessAction>
  | PutEffect<RobotUnassignedListPicturesSuccessAction>
  | PutEffect<RobotUnassignedListTelemetriesSuccessAction>
  | PutEffect<RobotUnassignedListLatestCtrsSuccessAction>
  | PutEffect<RobotUnassignedListErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const machinesResponseTask = yield* fork(machineService.listRobots, action.payload);
    const machinePicturesResponseTask = yield* fork(machineService.listPictures, action.payload);
    const machineTelemetriesResponseTask = yield* fork(machineService.robotsWithTelemetries, action.payload);
    const machineLatestCtrsResponseTask = yield* fork(machineService.listWithLatestCtr, action.payload);
    const machineLatestRoutinesResponseTask = yield* fork(machineService.listWithLatestRoutine, action.payload);

    const machinesResponse = yield* join(machinesResponseTask);
    yield* put(RobotDashboardActions.robotUnassignedListSuccess(machinesResponse));

    const machinePicturesResponse = yield* join(machinePicturesResponseTask);
    yield* put(RobotDashboardActions.robotUnassignedListPicturesSuccess(machinePicturesResponse));

    const machineTelemetriesResponse = yield* join(machineTelemetriesResponseTask);
    yield* put(RobotDashboardActions.robotUnassignedListTelemetriesSuccess(machineTelemetriesResponse));

    const machineLatestCtrsResponse = yield* join(machineLatestCtrsResponseTask);
    yield* put(RobotDashboardActions.robotUnassignedListLatestCtrsSuccess(machineLatestCtrsResponse));

    const machineLatestRoutinesResponse = yield* join(machineLatestRoutinesResponseTask);
    yield* put(RobotDashboardActions.robotUnassignedListLatestRoutinesSuccess(machineLatestRoutinesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotUnassignedListError({
        error,
      })
    );
  }
}

export function* robotListGroupedBySiteListSitesSaga(
  action: RobotListGroupedBySiteListSitesRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<SiteList>
  | JoinEffect
  | PutEffect<RobotListGroupedBySiteListSitesSuccessAction>
  | PutEffect<RobotListGroupedBySiteListSitesErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const sitesCleaningStatisticResponseTask = yield* fork(siteService.listWithCleaningStatistic, action.payload);

    const sitesCleaningStatisticResponse = yield* join(sitesCleaningStatisticResponseTask);
    yield* put(
      RobotDashboardActions.robotListGroupedBySiteListSitesWithCleaningStatisticSuccess(sitesCleaningStatisticResponse)
    );
  } catch (error) {
    console.error(error);

    yield* put(RobotDashboardActions.robotListGroupedBySiteListSitesError({ error }));
  }
}

export function* robotListGroupedBySiteListSitesMachinesSaga(
  action: RobotListGroupedBySiteListSitesRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<SiteList>
  | ForkEffect<SiteMachinesList>
  | ForkEffect<SiteMachinesListWithVariantData>
  | JoinEffect
  | PutEffect<RobotListGroupedBySiteListSitesWithRobotSuccessAction>
  | PutEffect<RobotListGroupedBySiteListSitesWithRobotPicturesSuccessAction>
  | PutEffect<RobotListGroupedBySiteListSitesErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const sitesResponseTask = yield* fork(siteService.listForRobotDashboard, action.payload);
    const siteMachinesResponseTask = yield* fork(siteService.listWithMachines, action.payload);
    const machinesPicturesResponseTask = yield* fork(siteService.listWithMachinesPictures, action.payload);
    const machinesTelemetriesResponseTask = yield* fork(siteService.listWithMachinesTelemetries, action.payload);
    const machinesLatestCtrResponseTask = yield* fork(siteService.listWithMachinesLatestCtr, action.payload);
    const machinesLatestRoutineResponseTask = yield* fork(siteService.listWithMachinesLatestRoutine, action.payload);

    const sitesResponse = yield* join(sitesResponseTask);
    yield* put(RobotDashboardActions.robotListGroupedBySiteListSitesSuccess(sitesResponse));

    const siteMachinesResponse = yield* join(siteMachinesResponseTask);
    yield* put(RobotDashboardActions.robotListGroupedBySiteListSitesWithRobotSuccess(siteMachinesResponse));

    const machinesPicturesResponse = yield* join(machinesPicturesResponseTask);
    yield* put(RobotDashboardActions.robotListGroupedBySiteListSitesWithRobotPicturesSuccess(machinesPicturesResponse));

    const machinesTelemetriesResponse = yield* join(machinesTelemetriesResponseTask);
    yield* put(
      RobotDashboardActions.robotListGroupedBySiteListSitesWithRobotTelemetriesSuccess(machinesTelemetriesResponse)
    );

    const machinesLatestCtrResponse = yield* join(machinesLatestCtrResponseTask);
    yield* put(
      RobotDashboardActions.robotListGroupedBySiteListSitesWithRobotLatestCtrSuccess(machinesLatestCtrResponse)
    );

    const machinesLatestRoutineResponse = yield* join(machinesLatestRoutineResponseTask);
    yield* put(
      RobotDashboardActions.robotListGroupedBySiteListSitesWithRobotLatestRoutineSuccess(machinesLatestRoutineResponse)
    );
  } catch (error) {
    console.error(error);

    yield* put(RobotDashboardActions.robotListGroupedBySiteListSitesError({ error }));
  }
}

export function* robotDashboardUnassignedRobotsKPIsSaga(
  action: RobotDashboardKPIsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<RobotDashboardKPIsResponse>
  | PutEffect<RobotDashboardKPIsSuccessAction>
  | PutEffect<RobotDashboardKPIsErrorAction>,
  void,
  IDependencies
> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(robotService.robotsKPIs, action.payload);
    yield* put(RobotDashboardActions.robotDashboardUnassignedRobotsKPIsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      RobotDashboardActions.robotDashboardUnassignedRobotsKPIsError({
        error,
      })
    );
  }
}

export function* onMachineTelemetryChanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as Record<string, string>;

  const machineUpdatedStatusData: MachineUpdateTelemetry = {
    ...machineUpdated,
    data: machineUpdatedData,
  };

  yield* put(RobotDashboardActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData }));
}

export function* onMachineStatusChanged(machineUpdated: MachineUpdate): Generator<PutEffect | CallEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as {
    robotStatus: RobotStatus;
  };

  const machineUpdatedStatusData: MachineUpdateRobotStatus = {
    ...machineUpdated,
    data: machineUpdatedData,
  };

  yield* put(
    RobotDashboardActions.robotDashboardIsComponentFadedOutActive({
      machineUpdated,
    })
  );
  yield* delay(500);
  yield* put(RobotDashboardActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData }));
  yield* put(RobotDashboardActions.robotDashboardIsComponentFadedOutDeactive());
}

export function* onMachineConnectionStatusChanged(machineUpdated: MachineUpdate): Generator<PutEffect | CallEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data) as {
    connectionStatus: MachineConnectionStatus;
  };

  const machineUpdatedStatusData: MachineUpdateConnectionStatus = {
    ...machineUpdated,
    data: machineUpdatedData,
  };

  yield* put(
    RobotDashboardActions.robotDashboardIsComponentFadedOutActive({
      machineUpdated,
    })
  );
  yield* delay(500);
  yield* put(RobotDashboardActions.robotByStatusRealTimePropertyChanged({ updatedData: machineUpdatedStatusData }));
  yield* put(RobotDashboardActions.robotDashboardIsComponentFadedOutDeactive());
}

export function* onMachineSiteChangedAssignedEvent(
  machineUpdated: MachineUpdate
): Generator<GetContextEffect | PutEffect | SelectEffect | CallEffect> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  yield* put(RobotDashboardActions.robotListRobotIdsInSpecificSite({ machineUpdated }));

  const siteRobotIds = yield* select(selectRobotListGroupedBySiteRobotIds) || [];
  const startAt = yield* select(selectPeriodStartDate);
  const endAt = yield* select(selectPeriodEndDate);

  const siteRobotIdsAfterAssigned = Array.isArray(siteRobotIds)
    ? [...siteRobotIds, machineUpdated.machineId]
    : [machineUpdated.machineId];

  try {
    const response = yield* call(
      robotService.robotsKPIs,
      RobotUtils.getCleaningDataInput(startAt, endAt, siteRobotIdsAfterAssigned)
    );

    yield* put(
      RobotDashboardActions.robotDashboardIsComponentFadedOutActive({
        machineUpdated,
      })
    );
    yield* delay(1000);
    yield* put(
      RobotDashboardActions.robotBySiteRealTimeAssignedSiteChanged({
        machineUpdated,
        robotsSiteCleaningStatisitic: response.robotDashboardKPIs,
      })
    );
    yield* put(RobotDashboardActions.robotDashboardIsComponentFadedOutDeactive());

    const robotUnassignedList = yield* select(selectRobotUnassignedListData) || [];
    const robotUnassignedIds = robotUnassignedList?.map(robot => robot.id) || [];

    yield* put(
      RobotDashboardActions.robotDashboardUnassignedRobotsKPIsRequest(
        RobotUtils.getCleaningDataInput(startAt, endAt, robotUnassignedIds)
      )
    );
  } catch (error) {
    console.error(error);
  }
}

export function* onMachineSiteChangedUnAssignedEvent(
  machineUpdated: MachineUpdate
): Generator<GetContextEffect | PutEffect | SelectEffect | CallEffect> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  yield* put(RobotDashboardActions.robotListRobotIdsInSpecificSite({ machineUpdated }));

  const siteRobotIds = yield* select(selectRobotListGroupedBySiteRobotIds) || [];
  const startAt = yield* select(selectPeriodStartDate);
  const endAt = yield* select(selectPeriodEndDate);

  const siteRobotIdsAfterUnassigned = siteRobotIds.filter(robotId => robotId !== machineUpdated.machineId);

  try {
    const response = yield* call(
      robotService.robotsKPIs,
      RobotUtils.getCleaningDataInput(startAt, endAt, siteRobotIdsAfterUnassigned)
    );

    yield* put(
      RobotDashboardActions.robotDashboardIsComponentFadedOutActive({
        machineUpdated,
      })
    );
    yield* delay(1000);
    yield* put(
      RobotDashboardActions.robotBySiteRealTimeUnassignedSiteChanged({
        machineUpdated,
        robotsSiteCleaningStatisitic: response.robotDashboardKPIs,
      })
    );
    yield* put(RobotDashboardActions.robotDashboardIsComponentFadedOutDeactive());

    const robotUnassignedList = yield* select(selectRobotUnassignedListData) || [];
    const robotUnassignedIds = robotUnassignedList?.map(robot => robot.id) || [];

    yield* put(
      RobotDashboardActions.robotDashboardUnassignedRobotsKPIsRequest(
        RobotUtils.getCleaningDataInput(startAt, endAt, robotUnassignedIds)
      )
    );
  } catch (error) {
    console.error(error);
  }
}

export function* onMachineSiteChanged(machineUpdated: MachineUpdate): Generator<PutEffect | SelectEffect | ForkEffect> {
  const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated.data || '');
  const eventData = machineUpdatedData?.event || '';
  const groupBy = yield* select(selectGroupBy);

  switch (groupBy) {
    case ROBOT_LIST_GROUP_BY.status:
      yield* put(RobotDashboardActions.robotByStatusRealTimeSiteChanged({ machineUpdated }));
      break;
    case ROBOT_LIST_GROUP_BY.site:
      switch (eventData) {
        case MachineSiteChangedEvent.MACHINE_ASSIGNED:
          yield* fork(onMachineSiteChangedAssignedEvent, machineUpdated);
          break;
        case MachineSiteChangedEvent.MACHINE_UNASSIGNED:
          yield* fork(onMachineSiteChangedUnAssignedEvent, machineUpdated);
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
}

export function* onMachineCleaningDataChanged(
  machineUpdated: MachineUpdate
): Generator<GetContextEffect | SelectEffect | PutEffect | CallEffect> {
  const { robotService } = yield* getContext<IDependencies>('dependencies');

  yield* put(RobotDashboardActions.robotListRobotIdsInSpecificSite({ machineUpdated }));

  const siteRobotIds = yield* select(selectRobotListGroupedBySiteRobotIds);
  const groupBy = yield* select(selectGroupBy);
  const robotIdsFilter = yield* select(selectRobotIdsFilter);
  const robotUnassignedList = yield* select(selectRobotUnassignedListData) || [];
  const startAt = yield* select(selectPeriodStartDate);
  const endAt = yield* select(selectPeriodEndDate);

  const robotUnassigned = robotUnassignedList?.find(robot => robot.id === machineUpdated.machineId);
  const robotUnassignedIds = robotUnassignedList?.map(robot => robot.id) || [];

  try {
    yield* delay(5000);
    if (groupBy === ROBOT_LIST_GROUP_BY.site) {
      if (!isNil(robotUnassigned)) {
        yield* put(
          RobotDashboardActions.robotDashboardUnassignedRobotsKPIsRequest(
            RobotUtils.getCleaningDataInput(startAt, endAt, robotUnassignedIds)
          )
        );
      }

      const response = yield* call(
        robotService.robotsKPIs,
        RobotUtils.getCleaningDataInput(startAt, endAt, siteRobotIds)
      );

      yield* put(
        RobotDashboardActions.robotBySiteRealTimeCleaningDataChangedInSite({
          machineUpdated,
          robotsSiteCleaningStatisitic: response.robotDashboardKPIs,
        })
      );
    }

    yield* put(RobotDashboardActions.robotDashboardRealTimeUpdateGetEvent({ machineUpdated }));

    yield* put(
      RobotDashboardActions.robotDashboardListRobotsWithTotalCleanedAreaRequest(
        RobotUtils.getCleaningDataInput(startAt, endAt, robotIdsFilter)
      )
    );
    yield* put(
      RobotDashboardActions.robotDashboardTotalCleanedHourRequest(
        RobotUtils.getCleaningDataInput(startAt, endAt, robotIdsFilter)
      )
    );
    yield* put(
      RobotDashboardActions.robotDashboardKPIsRequest(RobotUtils.getCleaningDataInput(startAt, endAt, robotIdsFilter))
    );
    yield* put(
      RobotDashboardActions.robotDashboardGetTasksCompletionRequest({
        filter: RobotUtils.getCleaningDataInput(startAt, endAt, robotIdsFilter).filter,
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* onMachineRoutineDataChanged(machineUpdated: MachineUpdate): Generator<PutEffect> {
  try {
    yield* put(RobotDashboardActions.robotDashboardRoutineRealTimeUpdate({ machineUpdated }));
  } catch (error) {
    console.error(error);
  }
}

export function* onEveryMachineUpdateSaga(
  message: GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>
): Generator<GetContextEffect | PutEffect<RobotDashboardRealTimeDataChangedAction> | SelectEffect | ForkEffect> {
  const machineUpdated = message.value.data.onMachineUpdate;
  const event = machineUpdated?.event;

  try {
    if (!isNil(event)) {
      switch (event) {
        case SubscriptionMachineEvent.TELEMETRY_CHANGED:
          yield* fork(onMachineTelemetryChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.MACHINE_STATUS_CHANGED:
          yield* fork(onMachineStatusChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.SITE_CHANGED:
          yield* fork(onMachineSiteChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.CLEANING_DATA_CHANGED:
          yield* fork(onMachineCleaningDataChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.MACHINE_CONNECTION_STATUS_CHANGED:
          yield* fork(onMachineConnectionStatusChanged, machineUpdated);
          break;

        case SubscriptionMachineEvent.MACHINE_ROUTINE_CHANGED:
          yield* fork(onMachineRoutineDataChanged, machineUpdated);
          break;

        default:
          break;
      }
    }
  } catch (error) {
    console.error('machineId', message.value.data.onMachineUpdate.machineId);
    console.error(error);
  }
}

export function* subscribeToMachineUpdate(
  subscribeAction: SubscribeToMachineUpdateAction
): Generator<
  | GetContextEffect
  | CallEffect<EventChannel<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>>
  | ForkEffect<never>
  | TakeEffect
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { observable, throttleDuration } = machineService.subscribeToMachineUpdate({
      customerId: subscribeAction.payload.customerId,
      machineId: subscribeAction.payload.machineId,
    });

    const channel = yield* call(
      SagaUtils.createChannelFromObservable<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>,
      observable
    );

    yield* throttle(throttleDuration, channel, onEveryMachineUpdateSaga);

    while (true) {
      const unsubscribeAction = yield* take(RobotDashboardActions.unsubscribeFromMachineUpdate);
      if (
        unsubscribeAction.payload.customerId === subscribeAction.payload.customerId &&
        unsubscribeAction.payload.machineId === subscribeAction.payload.machineId
      ) {
        channel.close();
        cancel();
      }
    }
  } catch (err) {
    console.error('Error while subscribing/unsubscribing to subscription:', err);
  }
}

export function* robotDashboardSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(
    RobotDashboardActions.robotDashboardListRobotsWithTotalCleanedAreaRequest,
    listRobotsWithTotalCleanedAreaSaga
  );
  yield* takeLatest(RobotDashboardActions.robotDashboardTotalCleanedHourRequest, listRobotsWithTotalCleanedHourSaga);
  yield* takeLatest(RobotDashboardActions.robotDashboardKPIsRequest, robotDashboardKPIsSaga);
  yield* takeLatest(RobotDashboardActions.robotDashboardKPIsForB50Request, robotDashboardKPIsForB50Saga);
  yield* takeLatest(RobotDashboardActions.robotDashboardKPIsForCV50Request, robotDashboardKPIsForCV50Saga);
  yield* takeLatest(RobotDashboardActions.robotDashboardGetTasksCompletionRequest, getRobotsTasksCompletionSaga);
  yield* takeLatest(RobotDashboardActions.robotDashboardGetSitesListRequest, getSitesListSaga);
  yield* takeLatest(RobotDashboardActions.robotListGroupedBySiteListSitesRequest, robotListGroupedBySiteListSitesSaga);
  yield* takeLatest(
    RobotDashboardActions.robotListGroupedBySiteListSitesMachinesRequest,
    robotListGroupedBySiteListSitesMachinesSaga
  );
  yield* takeLatest(RobotDashboardActions.robotListRequest, listRobotsSaga);
  yield* takeLatest(RobotDashboardActions.robotUnassignedListRequest, listUnassignedRobotsSaga);
  yield* takeLatest(
    RobotDashboardActions.robotDashboardUnassignedRobotsKPIsRequest,
    robotDashboardUnassignedRobotsKPIsSaga
  );
  yield* takeEvery(RobotDashboardActions.subscribeToMachineUpdate, subscribeToMachineUpdate);
}
