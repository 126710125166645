import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import classnames from 'classnames';
import { ThemeConstants } from '../../../../../../../../../../config/theme';
import { RobotBox } from '../../../RobotBox/RobotBox';
import { RobotBoxCV50 } from '../../../RobotCV50/RobotBoxCV50/RobotBoxCV50';
import { RobotCleaningStatus } from '../../../RobotCleaningStatus/RobotCleaningStatus';
import * as robotDashboardSelectors from '../../../../../state/RobotDashboardSelectors';
import { StyledRobotBoxList } from './RobotBoxList.styles';
import { InfoTooltipGlobalStyles } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip.global.styles';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import {
  MachineConnectionStatus,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { RobotStatusDisplayName } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Optional } from 'lib/types/Optional';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { NoStyleButton } from 'lib/components/Button/NoStyleButton/NoStyleButton';

export const RobotBoxGroup = ({
  robots,
  robotStatus,
  connectionStatus,
  isShowStatus,
}: {
  robots: Machine[];
  robotStatus: Optional<RobotStatus>;
  connectionStatus: MachineConnectionStatus;
  isShowStatus: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();

  const robotStatusDisplayName = RobotUtils.getRobotStatusDisplayName(robotStatus, connectionStatus);

  const areRobotListPicturesLoading = !!useSelector(robotDashboardSelectors.selectAreRobotListPicturesLoading);
  const areRobotListTelemetriesLoading = !!useSelector(robotDashboardSelectors.selectAreRobotListTelemetriesLoading);
  const areRobotListLatestCtrLoading = !!useSelector(robotDashboardSelectors.selectAreRobotListLatestCtrLoading);
  const areRobotListLatestRoutineLoading = !!useSelector(
    robotDashboardSelectors.selectAreRobotListLatestRoutineLoading
  );

  const robotRealTimeUpdatedId = useSelector(robotDashboardSelectors.selectRobotDashboardRealTimeRobotId);
  const isRobotCardFadedOut = useSelector(robotDashboardSelectors.selectRobotDashboardRealTimeIsComponentFadedOut);

  const handleOpenRobotDetailsPanel = (robotId: string): void => {
    dispatch(DrawersActions.showMachineDetailsDrawer({ machineId: robotId }));
  };

  useEffect(
    () => () => {
      dispatch(DrawersActions.hideMachineDetailsDrawer());
    },
    [dispatch]
  );

  return (
    <div className="robot-box-group__wrapper">
      <InfoTooltipGlobalStyles />
      <div className="robot-box-group__wrapper-header">
        <RobotCleaningStatus robotStatus={robotStatus} connectionStatus={connectionStatus} />
        <Tooltip
          title={<InfoTooltip content={`robotDashboard.tooltip.${robotStatusDisplayName}`} />}
          overlayClassName="tooltip-overlay"
          placement="bottomRight"
        >
          <div>
            <SvgIcon name="info" className="robot-dashboard__info-icon" />
          </div>
        </Tooltip>
      </div>
      <Row gutter={[ThemeConstants.XL_GAP, ThemeConstants.XL_GAP]} className="robot-box-group__row" justify="start">
        {robots.map(robot => (
          <Col
            key={robot.id}
            className={classnames({
              'robot-box__faded-out': robotRealTimeUpdatedId === robot.id && isRobotCardFadedOut,
              'robot-box__faded-in': robotRealTimeUpdatedId !== robot.id || !isRobotCardFadedOut,
            })}
          >
            <NoStyleButton onClick={(): void => handleOpenRobotDetailsPanel(robot.id)}>
              {RobotUtils.getRobotType(robot) === 'B50' ? (
                <RobotBox
                  robot={robot}
                  isShowStatus={isShowStatus}
                  areRobotListPicturesLoading={areRobotListPicturesLoading}
                  areRobotListTelemetriesLoading={areRobotListTelemetriesLoading}
                  areRobotListLatestCtrLoading={areRobotListLatestCtrLoading}
                  areRobotListLatestRoutineLoading={areRobotListLatestRoutineLoading}
                />
              ) : (
                <RobotBoxCV50
                  robot={robot}
                  isShowStatus={isShowStatus}
                  areRobotListPicturesLoading={areRobotListPicturesLoading}
                  areRobotListTelemetriesLoading={areRobotListTelemetriesLoading}
                  areRobotListLatestCtrLoading={areRobotListLatestCtrLoading}
                  areRobotListLatestRoutineLoading={areRobotListLatestRoutineLoading}
                />
              )}
            </NoStyleButton>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export const RobotBoxList = (): JSX.Element => {
  const robots = useSelector(robotDashboardSelectors.selectRobotListData) || [];
  const unassignedRobots = useSelector(robotDashboardSelectors.selectRobotUnassignedListData) || [];
  const robotsGroupedByStatus = RobotUtils.getRobotsGroupedByStatus([...robots, ...unassignedRobots]);
  const isHidingOfflineRobots = useSelector(robotDashboardSelectors.selectIsHidingOfflineRobots);

  return (
    <StyledRobotBoxList>
      {robotsGroupedByStatus[RobotStatusDisplayName.Cleaning].length > 0 && (
        <RobotBoxGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Cleaning].sort((a, b) => a.name.localeCompare(b.name))}
          robotStatus={RobotStatus.Autonomous}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.Exploration].length > 0 && (
        <RobotBoxGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Exploration].sort((a, b) =>
            a.name.localeCompare(b.name)
          )}
          robotStatus={RobotStatus.Exploration}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.Docking].length > 0 && (
        <RobotBoxGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Docking].sort((a, b) => a.name.localeCompare(b.name))}
          robotStatus={RobotStatus.Docking}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.Standby].length > 0 && (
        <RobotBoxGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Standby].sort((a, b) => a.name.localeCompare(b.name))}
          robotStatus={RobotStatus.Idle}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.NA].length > 0 && (
        <RobotBoxGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.NA].sort((a, b) => a.name.localeCompare(b.name))}
          robotStatus={null}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.Offline].length > 0 && !isHidingOfflineRobots && (
        <RobotBoxGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Offline].sort((a, b) => a.name.localeCompare(b.name))}
          robotStatus={RobotStatus.Autonomous}
          connectionStatus={MachineConnectionStatus.Offline}
          isShowStatus={false}
        />
      )}
    </StyledRobotBoxList>
  );
};
